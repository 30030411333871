<template>
    <div class="d-flex flex-row border-top align-items-center">
        <h6 style="margin-top: auto; margin-bottom: auto" class="">{{$t('Saved Filters')}}</h6>
        <div class="ms-2"><FieldSort :dataObject="filterObject.filtersListDO" :fields="['FilterName', 'Updated']"></FieldSort></div>
    </div>
    <Overlay v-if="filterObject.filtersListDO.state.isLoading"/>
    <div class="flex flex-column" style="overflow-y:auto">
        <FiltersList :filterObject="filterObject" showTemplates></FiltersList>
        <!--
        <h6 class="mt-1 pt-2 mb-0" v-if="filterObject.filtersListDO.data.filter(x=>!x.mine).length">{{$t('Shared Filters')}} </h6>
        -->
            <div class="form-check form-switch" v-if="filterObject.filtersListDO.data.filter(x=>!x.mine && x.Hidden).length">
                <input class="form-check-input" type="checkbox" id="_include_hidden" v-model="includeHidden">
                <label class="form-check-label" for="_include_hidden">{{$t('Include hidden')}}</label>
            </div>

        <FiltersList :filterObject="filterObject" showTemplates sharedOnly v-if="filterObject.filtersListDO.data.filter(x=>!x.mine).length" :includeHidden = "includeHidden"></FiltersList>
    </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import FiltersList from './components.Filters.List.vue';
import { Overlay } from 'o365-ui-components';
import FieldSort from 'o365.libraries.vue.components.FieldSort.vue'; 
const props = defineProps({
    filterObject: null,
    showTemplates: {
        type: Boolean,
        default: false,
    }

});
const includeHidden = ref(false);

</script>
